import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppEUSvenskar = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Rädda vår framtid! Rösta i EU-valet - så här gör du!"
      description="Klicka på länken och skicka ett meddelande till dina kompisar på WhatsApp - ge dem en röst ➡️"
      shareImage="svenska"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Hej!
Är du Svensk och bor i Storbritannien?
Vi har rätt att rösta i EU-valet här i UK!

💥 Skicka det här meddelandet till alla dina svenska vänner i Storbritannien - det här är vårt hem också! 💥
Klicka här: https://saveourfuture.uk/eusvenskar

*Registrera för att rösta senast 7 Maj*
Följ de enkla stegen här:
https://saveourfuture.uk/simple

(Gör det nu direkt!)

*VIKTIG !!!*
💥 Skicka det här meddelandet till alla dina svenska vänner i Storbritannien - det här är vårt hem också! 💥
Klicka här: https://saveourfuture.uk/eusvenskar

*DEN HÄR GÅNGEN KAN DU GÖRA DIN RÖST HÖRD !!!*`}
    />
    <Interstitial>
      <p>Välj fem toppvänner eller grupper för att skicka ditt Whatsapp-meddelande</p>
      <p>Genererar meddelande nu</p>
      <p>Du kan anpassa meddelandet i nästa steg ...</p>
    </Interstitial>
    <Disclaimer />
  </Layout>
);

WhatsAppEUSvenskar.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppEUSvenskar;

export const query = graphql`
  query EUSvenskarQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
